<template>
  <div class="verify-main-content">
    <div class="verify-legend">
      {{ $locale.words["become_creator_title"] }}
    </div>
    <div class="verify-features">
      <div class="verify-features-content">
        <CreatorBecomeFeature
          :card="{ label: $locale.words['setup_your_own_domain'], icon: 'globe', desc: $locale.words['setup_your_own_domain_desc'] }"
        />
        <CreatorBecomeFeature
          :card="{ label: $locale.words['your_content_is_safe'], icon: 'mf_lock', desc: $locale.words['your_content_is_safe_desc'] }"
        />
        <CreatorBecomeFeature
          :card="{
            label: $locale.words['setup_your_subscription'],
            icon: 'mf_credits',
            iconSize: '300%',
            desc: $locale.words['setup_your_subscription_desc'],
          }"
        />
        <router-link to="/user/creator?apply=step1" pointer dactive :style="$style(applyStyle)">{{ $locale.words["apply_now"] }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CreatorBecomeFeature: () => import("./CreatorBecomeHomeFeature.vue"),
  },
  data: function() {
    return {
      applyStyle: {
        display: "inline-block",
        width: "100%",
        padding: "1rem 2rem",
        margin: "var(--dpading) 0",
        color: "#fff",
        "background-image": "var(--sec-gradient)",
        "border-radius": "calc(var(--mpadding) / 2)",
        "text-align": "center",
        "user-select": "none",
      },
    };
  },
};
</script>
